import React from 'react';
import Layout from '../templates/layout';
import BasePageContent from '../components/utils/BasePageContent';
import { Box, Typography } from '@mui/material';
import Footer from '../components/Footer';
import Seo from '../components/utils/Seo';

const policyUrl = '/FiberPay_Polityka_Prywatności_20200818.pdf';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Layout>
          <BasePageContent title='Polityka prywatności'>
            <Box>
              <Typography variant='h6'>
                Przywiązujemy dużą wagę do poszanowania Twojej prywatności
              </Typography>
              <Typography>
                Korzystając z naszych usług, powierzasz nam swoje dane.
                Zapewniamy ich bezpieczeństwo oraz umożliwiamy Ci kontrolę nad
                nimi. W naszym dokumencie Polityki prywatności znajdziesz
                informację o tym, jakie prawa Ci przysługują, jakie dane
                zbieramy, w jakim celu oraz jak możesz je aktualizować i usuwać
                oraz nimi zarządzać.
              </Typography>
              <a download href={policyUrl} className='link-inline'>
                Pobierz (PDF)
              </a>
            </Box>
          </BasePageContent>
        </Layout>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export const Head = ({ location }) => (
  <Seo
    location={location}
    title='Polityka prywatności'
    description='Korzystając z naszych usług, powierzasz nam swoje dane. Zapewniamy ich bezpieczeństwo oraz umożliwiamy Ci kontrolę nad nimi'
  />
);

export default PrivacyPolicy;
